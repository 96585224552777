// ImNavbar.js
import React from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "/Users/ayushrawat/Desktop/landing_page/corpsol/src/components/Footer/footer-logo.png";

const ImNavbar = () => {
  const navbarStyle = {
    backgroundColor: "transparent",
    display: "flex",
    padding: "20px",
    fontFamily: "sans-serif",
  };

  const linkStyle = {
    color: "black",
    textDecoration: "none",
    fontFamily: "sans-serif",
    fontWeight: "600",
  };

  const activeLinkStyle = {
    color: "#905FD0",
    textDecoration: "none",
    fontFamily: "sans-serif",
    fontWeight: "600",
  };

  const location = useLocation();

  return (
    <div style={navbarStyle}>
      <div
        style={{
          paddingLeft: "1rem",
          fontFamily: "sans-serif",
          color: "#905FD0",
        }}
      >
        <img
          src={logo}
          alt="logo"
          style={{
            width: "30%",
            height: "100%",
            overflow: "hidden",
          }}
        />
      </div>
      <div
        style={{
          width: "60rem",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          paddingLeft: "15%",
          paddingRight: "2rem",
        }}
      >
        <Link
          to="/home"
          style={location.pathname === "/home" ? activeLinkStyle : linkStyle}
        >
          <p>Home</p>
        </Link>
        <Link
          to="/about"
          style={location.pathname === "/about" ? activeLinkStyle : linkStyle}
        >
          <p>About Us</p>
        </Link>
        <Link
          to="/services"
          style={
            location.pathname === "/services" ? activeLinkStyle : linkStyle
          }
        >
          <p>Our Services</p>
        </Link>

        <Link
          to="/testimonials"
          style={
            location.pathname === "/testimonials" ? activeLinkStyle : linkStyle
          }
        >
          <p>Client's Testimonials</p>
        </Link>
        <Link
          to="/contact"
          style={location.pathname === "/contact" ? activeLinkStyle : linkStyle}
        >
          <p>Contact Us</p>
        </Link>
      </div>
    </div>
  );
};

export default ImNavbar;
