import React from "react";
import logo from "/Users/ayushrawat/Desktop/landing_page/corpsol/src/components/Footer/footer-logo.png";
import {
  FaFacebook,
  FaTwitter,
  FaInstagram,
  FaYoutube,
  FaLinkedin,
} from "react-icons/fa";

const Footer = () => {
  const footerStyle = {
    height: "12rem",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "20px",
    backgroundColor: "rgba(204, 204, 255, 0.3)",
  };

  const socialStyle = {
    display: "flex",
    alignItems: "center",
  };

  const iconStyle = {
    fontSize: "24px",
    margin: "0 10px",
  };

  const logoStyle = {
    maxWidth: "350px",
    paddingTop: "1rem",
  };

  const contactStyle = {
    display: "flex",
    flexDirection: "column",
    fontSize: "16px",
  };

  return (
    <div>
      <footer style={footerStyle}>
        <div style={socialStyle}>
          <a
            href="https://www.facebook.com/corpbrandsolutions/?show_switched_toast=0&show_invite_to_follow=0&show_switched_tooltip=0&show_podcast_settings=0&show_community_review_changes=0&show_community_rollback=0&show_follower_visibility_disclosure=0"
            style={iconStyle}
          >
            <FaFacebook />
          </a>
          <a
            href="https://twitter.com/corpbrandsol/status/1686817696971993088?s=20"
            style={iconStyle}
          >
            <FaTwitter />
          </a>
          <a
            href="https://www.instagram.com/corpbrandsolutions/"
            style={iconStyle}
          >
            <FaInstagram />
          </a>
          <a href="YOUR_YOUTUBE_LINK" style={iconStyle}>
            <FaYoutube />
          </a>
        </div>
        <div>
          <img src={logo} alt="Logo" style={logoStyle} />
        </div>
        <div style={socialStyle}>
          <div style={contactStyle}>
            <p style={{ margin: 0, fontWeight: "bold" }}>
              Phone: +91 95601 82277
            </p>
            <p style={{ margin: 0, fontWeight: "bold" }}>
              Email: sunil@corpbrandsol.com
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
