import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import bg from "/Users/ayushrawat/Desktop/landing_page/corpsol/src/pages/Contact/contact_bg.avif";

const ImCampaignManagement = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  const items = [
    {
      heading: "GOOGLE ADWORDS",
      tag: "Are your customers raving about you on social media? Share their great stories to help turn potential customers into loyal ones.",
    },
    {
      heading: "SOCIAL MEDIA ADS",
      tag: "Customers have questions, you have answers. Display the most frequently asked questions, so everybody benefits.",
    },
  ];

  const containerStyle = {
    fontFamily: "Raleway, sans-serif", // Set font-family to Raleway
    display: "flex",
    justifyContent: "center",
    position: "relative",
    width: "100%",
    height: "100vh",
    overflow: "hidden",
    flexDirection: "row",
    flexWrap: "wrap",
    margin: 0,
    padding: 0,
  };

  const boxStyle = {
    fontFamily: "Raleway, sans-serif", // Set font-family to Raleway
    width: "100%",
    margin: "1%",
    padding: "20px",
    backgroundColor: "#f0f0fa",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    borderRadius: "8px",
    boxSizing: "border-box",
    marginBottom: "50px",
  };

  const backgroundImageStyle = {
    content: "''",
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundImage: `url(${bg})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    opacity: 0.2,
    zIndex: -1,
  };

  const contentStyle = {
    color: "#000",
    textAlign: "center",
    width: "80%",
  };

  const headingStyle = {
    fontWeight: "bold",
    marginBottom: "10px",
  };

  const pStyle = {
    fontSize: "18px",
    lineHeight: "1.5",
  };

  return (
    <div style={containerStyle}>
      <div style={backgroundImageStyle}></div>
      <div style={contentStyle} data-aos="zoom-in">
        <p style={{ fontSize: "48px" }}>Campaign Management Services</p>
        {items.map((item, index) => (
          <div
            key={index}
            style={boxStyle}
            data-aos="zoom-in"
            data-aos-duration="1500"
          >
            <h2 style={headingStyle}>{item.heading}</h2>
            <p style={pStyle}>{item.tag}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImCampaignManagement;
