import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import bg from "/Users/ayushrawat/Desktop/landing_page/corpsol/src/pages/Contact/contact_bg.avif";
import ormser from "/Users/ayushrawat/Desktop/landing_page/corpsol/src/pages/Services/images/ormser.png";
import growth from "/Users/ayushrawat/Desktop/landing_page/corpsol/src/pages/Services/images/growth.png";
import success from "/Users/ayushrawat/Desktop/landing_page/corpsol/src/pages/Services/images/success.webp";

const ImORM = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  const items = [
    {
      heading: "REPUTATION MANAGEMENT",
      tag: " The process of monitoring how consumers perceive your brand and taking strategic action to improve your brand's image ",
    },
    {
      heading: "CRISIS MANAGEMENT",
      tag: " The practice of preparing for minimizing their damage and disruption, and getting an organization back on track as quickly as possible. ",
    },
    {
      heading: "INDIVIDUAL REPUTATION MANAGEMENT",
      tag: " Tells others who you are, what you stand for, and what values you contribute. ",
    },
    {
      heading: "SOCIAL MEDIA MONITORING",
      tag: "The process of collecting social conversations and messages into a database of useful information.  ",
    },
  ];

  const ormservices = [
    {
      heading: "CRISIS MANAGEMENT",
      about:
        "  Our skilled ORM team minimizes crisis impact, ensuring brand competitiveness, and aims to terminate all types of crises with minimal damage. ",
    },
    {
      heading: "STRATEGIC PR",
      about:
        " We are top PR professionals who will elevate your business by providing comprehensive online reputation management services to reach your target audience. ",
    },
    {
      heading: "REVIEW MANAGEMENT",
      about:
        " Our advanced review management services can enhance user perception of your business by tracking reviews that can potentially lower ratings and remove them positively. ",
    },
    {
      heading: "GOOGLE AUTOFILLS",
      about:
        " Google Auto-fills significantly enhances your business's reputation by providing only positive suggestions, increasing the chances of success. ",
    },
    {
      heading: "ONLINE MONITORING",
      about:
        " Our online reputation management services utilize advanced digital tools to regularly monitor your online reputation, providing tailored solutions to enhance customer satisfaction. ",
    },
    {
      heading: "SOCIAL MEDIA MONITORING",
      about:
        " We offer comprehensive monitoring and enhancement of your social media visibility, utilizing advanced tools and expertise to analyze your brand and enhance its engagement. ",
    },
  ];

  const individualservices = [
    {
      heading: "PROFILE OPTIMIZATION",
      about:
        "We use expertly altered profiles to remove negative listings and enhance your online presence. ",
    },
    {
      heading: "NEGATIVE CONTENT MANAGEMENT",
      about:
        " Our Identity reputation management experts handle content, provide top-notch services, and ensure the longevity of your online reputation. ",
    },
    {
      heading: "ONLINE PUBLIC RELATIONS",
      about:
        " We create and promote positive content, including articles, news, business profiles, and interviews, to maintain and enhance your search engine ranking. ",
    },
    {
      heading: "MEDIA INTELLIGENCE TOOLS",
      about:
        "We utilize cutting-edge media intelligence tools to analyze the opinions of your audience to monitor your online image's competitive insights. ",
    },
    {
      heading: "APPLY THE RIGHT ORM STRATEGY",
      about:
        "Our experts are utilizing top-notch ORM strategies to enhance your online presence, ensuring significant results are achieved through effective implementation. ",
    },
    {
      heading: "CREATE AN ONLINE PRESENCE OF YOUR ACTIVITIES",
      about:
        " We are dedicated to protecting your privacy on social media to prevent online defamation and prevent anyone from exploiting your absence. ",
    },
  ];

  const containerStyle = {
    fontFamily: "Raleway, sans-serif", // Set font-family to Raleway
    display: "flex",
    justifyContent: "center",
    position: "relative",
    width: "100%",
    height: "120vh",
    overflow: "hidden",
  };

  const containerStyle2 = {
    top: "0",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
    padding: "2rem 5rem",
  };

  const backgroundImageStyle = {
    content: "''",
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundImage: `url(${bg})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    opacity: 0.2,
    zIndex: -1,
  };

  const contentStyle = {
    fontFamily: "Raleway, sans-serif", // Set font-family to Raleway
    position: "absolute",
    color: "#000",
    zIndex: 1,
    textAlign: "center",
  };

  const boxStyle = {
    fontFamily: "Raleway, sans-serif", // Set font-family to Raleway
    width: "100%",
    margin: "1%",
    padding: "20px",
    backgroundColor: "#f0f0fa",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    borderRadius: "8px",
    boxSizing: "border-box",
    marginBottom: "50px",
  };

  const boxStyle3 = {
    fontFamily: "Raleway, sans-serif", // Set font-family to Raleway
    width: "19rem",
    height: "15rem",
    margin: "1rem",
    backgroundColor: "#f0f0fa",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    borderRadius: "8px",
    transition: "background-color 0.3s ease-in-out",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    cursor: "pointer",
    marginBottom: "50px",
  };

  const headingStyle = {
    fontWeight: "bold",
    marginBottom: "10px",
  };

  const pStyle = {
    fontSize: "18px",
    lineHeight: "1.5",
  };

  return (
    <div>
      <div style={containerStyle}>
        <div style={backgroundImageStyle}></div>
        <div style={contentStyle} data-aos="zoom-in">
          <p style={{ fontSize: "48px" }}>ORM Services</p>
          {items.map((item, index) => (
            <div
              key={index}
              style={boxStyle}
              data-aos="zoom-in"
              data-aos-duration="1500"
            >
              <h2 style={headingStyle}>{item.heading}</h2>
              <p style={pStyle}>{item.tag}</p>
            </div>
          ))}
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingTop: "5rem",
        }}
        data-aos="zoom-in"
        data-aos-duration="2000"
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <img
            src={ormser}
            alt="seo"
            style={{ width: "8rem", height: "4rem", paddingRight: "2rem" }}
          />
          <h1>ONLINE REPUTATION MANAGEMENT</h1>
        </div>
        <h2 style={{ paddingBottom: "3rem" }}>
          Best ORM Services Company in INDIA
        </h2>
        <p
          style={{ fontSize: "22px", padding: "0 10rem", textAlign: "center" }}
        >
          {" "}
          We are proud to announce ourselves as one of the Leading Online
          Reputation Management companies in INDIA. Our cutting-edge Online
          Reputation Management Services cover all the aspects that can help in
          keeping you ahead of the curve.{" "}
        </p>
        <h1 style={{ paddingTop: "5rem" }}>Our Expertise</h1>
        <div
          style={containerStyle2}
          data-aos="flip-down"
          data-aos-duration="2500"
        >
          {ormservices.map((service, index) => (
            <div key={index} style={boxStyle3}>
              <h3 style={{ textAlign: "center" }}>{service.heading}</h3>
              <p
                style={{
                  textAlign: "center",
                  margin: "0 1rem",
                }}
              >
                {service.about}
              </p>
            </div>
          ))}
        </div>
        <h1 data-aos="zoom-in" data-aos-duration="2000">
          As an ORM Company – How Do We Help Companies Grow?
        </h1>
        <div
          style={{ display: "flex", flexDirection: "row", padding: "0 5rem" }}
        >
          <img
            src={growth}
            alt="growthimg"
            style={{ width: "30%" }}
            data-aos="fade-left"
            data-aos-duration="2000"
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "3rem 4rem",
            }}
            data-aos="fade-right"
            data-aos-duration="2000"
          >
            <h2>
              EXPAND YOUR ONLINE REACH WITH THE LEADING ORM COMPANY HAVING
              PROFICIENT EXPERTS
            </h2>
            <p>
              Corp-brand solutions is a leading Best ORM Company that employs
              skilled professionals to monitor and manage online marketplace
              activities related to your brand. They understand the importance
              of reputation for businesses and develop an ideal plan to protect
              and enhance your brand's and company's online reputation using
              various reputation management solutions.{" "}
            </p>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "3rem 7rem",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "0 1.5rem",
            }}
            data-aos="fade-left"
            data-aos-duration="2000"
          >
            <h2>WE’LL MAKE YOU REACH TOWARDS SUCCESS</h2>
            <p>
              Our innovative, customized services ensure quality and
              satisfaction for clients. Our 3-phase monitoring, review, and
              management approach, along with online reputation management
              experts, improves business reputation and reduces negative search
              engine results.
            </p>
          </div>
          <img
            src={success}
            alt="growthimg"
            style={{ width: "20%" }}
            data-aos="fade-right"
            data-aos-duration="2000"
          />
        </div>
        <div style={{ paddingTop: "5rem" }}>
          <h1
            style={{ textAlign: "center" }}
            data-aos="zoom-in"
            data-aos-duration="2000"
          >
            Customized Individual Reputation Management Strategies
          </h1>
          <div
            style={containerStyle2}
            data-aos="flip-down"
            data-aos-duration="2500"
          >
            {individualservices.map((service, index) => (
              <div key={index} style={boxStyle3}>
                <h3 style={{ textAlign: "center" }}>{service.heading}</h3>
                <p
                  style={{
                    textAlign: "center",
                    margin: "0 1rem",
                  }}
                >
                  {service.about}
                </p>
              </div>
            ))}
          </div>
        </div>
        <div>
          <h1 style={{ textAlign: "center", paddingTop: "15rem" }}>
            Social Media Monitoring and Response Management Company
          </h1>

          <p
            style={{
              fontSize: "22px",
              padding: "0 5rem",
              textAlign: "center",
            }}
          >
            As a top Social Media Response management company in Noida, India,
            we offer real-time insights into your brand's social media presence,
            enabling you to improve your performance and reach the right
            audience. Our experts focus on reacting in real-time with consumers
            to maximize the value of your social media campaigns, ensuring your
            brand regains top positions in listings.
          </p>
          <h1 style={{ textAlign: "center", paddingTop: "10rem" }}>
            Make Your Brand’s Vibrant Presence
          </h1>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "2rem 10rem ",
              textAlign: "left",
            }}
          >
            <h2>TREND ANALYSIS</h2>
            <h3>
              We go deep to actively recognize the current and forecast upcoming
              trends so that you can prepare for the future challenges and
              anticipate the change in the consumer’s mind.
            </h3>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "2rem 10rem",
              textAlign: "right",
            }}
          >
            <h2>EFFECTIVE RESPONSE-AND-RESOLUTION STRATEGIES</h2>
            <h3>
              Our experts undergo extensive training to produce results, uncover
              opportunities and identify areas for improvement.{" "}
            </h3>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "2rem 10rem",
              textAlign: "left",
            }}
          >
            <h2>KEYWORD-BASED MONITORING</h2>
            <h3>
              We are obsessed with results and therefore, we publish powerful
              content with strong keywords to ramp up your business.{" "}
            </h3>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "2rem 10rem",
              textAlign: "right",
            }}
          >
            <h2>PERFORM COMPETITOR ANALYSIS</h2>
            <h3>
              Our Customer response management services experts adapt
              qualitative research skills and have the special ability to
              provide consumers’ views about your competitor.{" "}
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImORM;
