import React, { useEffect } from "react";
import AOS from "aos";
import "./styles.css";
import "aos/dist/aos.css";
import bg from "/Users/ayushrawat/Desktop/landing_page/corpsol/src/pages/Contact/contact_bg.avif";
import seo from "/Users/ayushrawat/Desktop/landing_page/corpsol/src/pages/Services/images/seopage.png";
import localseo from "/Users/ayushrawat/Desktop/landing_page/corpsol/src/pages/Services/images/localseo.jpeg";
import globalseo from "/Users/ayushrawat/Desktop/landing_page/corpsol/src/pages/Services/images/globalseo.webp";
import ecomseo from "/Users/ayushrawat/Desktop/landing_page/corpsol/src/pages/Services/images/ecomseo.webp";
import entseo from "/Users/ayushrawat/Desktop/landing_page/corpsol/src/pages/Services/images/entseo.webp";
import smallseo from "/Users/ayushrawat/Desktop/landing_page/corpsol/src/pages/Services/images/smallseo.png";
import onlineseo from "/Users/ayushrawat/Desktop/landing_page/corpsol/src/pages/Services/images/onlineseo.png";
import smo from "/Users/ayushrawat/Desktop/landing_page/corpsol/src/pages/Services/images/smo.jpeg";

const ImDigitalMarketing = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  const items = [
    {
      heading: "SEARCH ENGINE OPTIMIZATION",
      tag: "If customers can’t find it, it doesn’t exist. Clearly list and describe the services you offer. Also, be sure to showcase a premium service.",
    },
    {
      heading: "SOCIAL MEDIA MANAGEMENT",
      tag: "Having a big sale, on-site celebrity, or other event? Be sure to announce it so everybody knows and gets excited about it.",
    },
    {
      heading: "PAGE/PROFILE VERIFICATION",
      tag: "Are your customers raving about you on social media? Share their great stories to help turn potential customers into loyal ones.",
    },
    {
      heading: "INFLUENCER MARKETING",
      tag: "Running a holiday sale or weekly special? Definitely promote it here to get customers excited about getting a sweet deal.",
    },
    {
      heading: "ONLINE PRESS RELEASE",
      tag: "Customers have questions, you have answers. Display the most frequently asked questions, so everybody benefits.",
    },
    {
      heading: "WEBSITE DESIGNING",
      tag: "Have you opened a new location, redesigned your shop, or added a new product or service? Don't keep it to yourself, let folks know.",
    },
  ];

  const services = [
    {
      img: localseo,
      heading: "LOCAL SEO SERVICES",
      about:
        "Our affordable local search engine optimization services in India ensure maximum website conversion levels and improved digital presence. ",
    },
    {
      img: globalseo,
      heading: "GLOBAL SEO SERVICES",
      about:
        "CorpBrand Sol offers global search engine optimization services, allowing businesses to connect with customers worldwide, making them the top SEO Agency in Delhi. ",
    },
    {
      img: ecomseo,
      heading: "E-COMMERCE SEO SERVICES",
      about:
        " Our professional and advanced services for e-commerce search engine optimization aim to help your website rank higher in search engines for quick progress. ",
    },
    {
      img: entseo,
      heading: "ENTERPRISE SEO SERVICES",
      about:
        "We will dedicate our time and effort to comprehend your website and business objectives, offering strategic enterprise search engine optimization services to elevate your online presence. ",
    },
    {
      img: smallseo,
      heading: "SEO FOR SMALL BUSINESS",
      about:
        " CorpBrand sol, India's renowned SEO agency, offers SMBs affordable, tailored solutions for increased search visibility and rapid business growth. ",
    },
    {
      img: onlineseo,
      heading: "ONLINE MARKETPLACE SEO SERVICE",
      about:
        "We offer exceptional online marketplace SEO services, utilizing best practices and strategies to attract organic traffic and set you apart from competitors. ",
    },
  ];

  const smoservices = [
    {
      heading: "SOCIAL MEDIA AUDIT & STRATEGY",
      about:
        " With our value-worthy social media marketing packages setting up your brand on social media gets easier. We will study your needs and pick the right social media platform for you.",
    },
    {
      heading: "SOCIAL ADVERTISING",
      about:
        "Having a big sale, on-site celebrity, or other event? Be sure to announce it so everybody knows and gets excited about it.",
    },
    {
      heading: "CONTENT DEVELOPMENT & PROMOTION",
      about:
        "Are your customers raving about you on social media? Share their great stories to help turn potential customers into loyal ones.",
    },
    {
      heading: "MULTI-CHANNEL INTEGRATION",
      about:
        "Running a holiday sale or weekly special? Definitely promote it here to get customers excited about getting a sweet deal.",
    },
    {
      heading: "SOCIAL LISTENING & MONITORING",
      about:
        "Have you opened a new location, redesigned your shop, or added a new product or service? Don't keep it to yourself, let folks know.",
    },
    {
      heading: "ANALYTICS & REPORTING",
      about:
        "Customers have questions, you have answers. Display the most frequently asked questions, so everybody benefits.",
    },
  ];

  const containerStyle = {
    display: "flex",
    justifyContent: "center",
    position: "relative",
    width: "100%",
    height: "160vh",
    overflow: "hidden",
    fontFamily: "Raleway, sans-serif", // Apply Raleway font
  };

  const boxStyle = {
    width: "100%",
    margin: "1%",
    padding: "20px",
    backgroundColor: "#f0f0fa",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    borderRadius: "8px",
    boxSizing: "border-box",
    marginBottom: "50px",
  };

  const backgroundImageStyle = {
    content: "''",
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundImage: `url(${bg})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    opacity: 0.2,
    zIndex: -1,
  };

  const contentStyle = {
    position: "absolute",
    color: "#000",
    zIndex: 1,
    textAlign: "center",
  };

  const containerStyle2 = {
    top: "0",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
    padding: "2rem 5rem",
  };

  const boxStyle3 = {
    width: "19rem",
    height: "15rem",
    margin: "1rem",
    backgroundColor: "#f0f0fa",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    borderRadius: "8px",
    transition: "background-color 0.3s ease-in-out",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    cursor: "pointer",
    marginBottom: "50px",
  };

  const handleActive = (event) => {
    event.target.style.backgroundColor = "#dcdcf9";
  };

  const handleInactive = (event) => {
    event.target.style.backgroundColor = "#f0f0fa";
  };

  return (
    <div>
      <div style={containerStyle}>
        <div style={backgroundImageStyle}></div>
        <div style={contentStyle} data-aos="zoom-in" data-aos-duration="2000">
          <p style={{ fontSize: "48px" }}>DIGITAL MARKETING SERVICES</p>
          {items.map((item, index) => (
            <div key={index} style={boxStyle} data-aos="zoom-in">
              <h2 style={{ fontWeight: "bold", marginBottom: "10px" }}>
                {item.heading}
              </h2>
              <p style={{ fontSize: "18px", lineHeight: "1.5" }}>{item.tag}</p>
            </div>
          ))}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",

          padding: "5rem 0",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <img src={seo} alt="seo" style={{ width: "5rem", height: "5rem" }} />
          <h1
            data-aos="zoom-in"
            data-aos-duration="1500"
            style={{ fontSize: "35px" }}
          >
            SEARCH ENGINE OPTIMISATION
          </h1>
        </div>
        <p
          style={{ fontSize: "30px", textAlign: "center" }}
          data-aos="fade-right"
          data-aos-duration="2000"
        >
          Our company is renowned for its exceptional SEO services
        </p>
        <p
          style={{ fontSize: "20px", padding: "0 10rem", textAlign: "center" }}
          data-aos="fade-right"
          data-aos-duration="2000"
        >
          We believe in Quality wins over Quantity and hence, we target the
          right audience to boost your business’ performance. Our SEO Services
          improve the online visibility of your business with original and
          high-quality optimized content. As a result-oriented and ethical and
          the Best SEO Services Agency in Noida, India, Corpbrand Sol knows that
          optimized content makes any brand more attractive and visitor stays
          longer on such readable articles that have never been explored
          before.Shooting your website to rank higher, Value4Brand is the Best
          SEO Agency in Delhi and we will make sure that great content gets
          uncovered to your audience that it has never explored before.
        </p>
      </div>
      <h2 style={{ textAlign: "center" }}>OUR SEO SERVICES</h2>
      <div style={containerStyle2} data-aos="zoom-in" data-aos-duration="2000">
        {services.map((service, index) => (
          <div
            key={index}
            className="boxStyle2"
            onMouseDown={handleActive}
            onMouseUp={handleInactive}
          >
            <img
              src={service.img}
              alt="service"
              style={{ width: "60%", height: "7rem", marginTop: "4rem" }}
            />

            <h3 style={{ textAlign: "center" }}>{service.heading}</h3>
            <p
              style={{
                textAlign: "center",
                margin: "0 1rem",
                fontWeight: "bold",
              }}
            >
              {service.about}
            </p>
          </div>
        ))}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          paddingTop: "5rem",
        }}
      >
        <img src={smo} alt="seo" style={{ width: "8rem", height: "6rem" }} />
        <h1
          style={{ fontSize: "42px" }}
          data-aos="zoom-in"
          data-aos-duration="1500"
        >
          SOCIAL MEDIA MANAGEMENT
        </h1>
      </div>
      <h2
        style={{ textAlign: "center" }}
        data-aos="zoom-in"
        data-aos-duration="2000"
      >
        Our SMO Plans will Help You Build Your Brand on Social Media
      </h2>
      <div
        style={containerStyle2}
        data-aos="flip-down"
        data-aos-duration="2500"
      >
        {smoservices.map((service, index) => (
          <div
            key={index}
            style={boxStyle3}
            onMouseDown={handleActive} // Use onMouseDown for :active effect
            onMouseUp={handleInactive}
          >
            <h3 style={{ textAlign: "center" }}>{service.heading}</h3>
            <p
              style={{
                textAlign: "center",
                margin: "0 1rem",
              }}
            >
              {service.about}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImDigitalMarketing;
