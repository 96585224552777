import React from "react";
import { FaFacebook, FaTwitter, FaInstagram } from "react-icons/fa";
import TypingText from "../../components/TypingText/TypingText";
import img1 from "/Users/ayushrawat/Desktop/landing_page/corpsol/src/pages/Home/images/homepagebg.png";
import img2 from "/Users/ayushrawat/Desktop/landing_page/corpsol/src/pages/Home/images/working-PhotoRoom.png-PhotoRoom.png";
import img7 from "/Users/ayushrawat/Desktop/landing_page/corpsol/src/pages/Home/images/dm.png";
import img8 from "/Users/ayushrawat/Desktop/landing_page/corpsol/src/pages/Home/images/cm.jpeg";
import img9 from "/Users/ayushrawat/Desktop/landing_page/corpsol/src/pages/Home/images/bl.jpeg";
import bg from "/Users/ayushrawat/Desktop/landing_page/corpsol/src/pages/Contact/contact_bg.avif";

const iconStyle = {
  fontSize: "40px",
  margin: "0 10px",
  cursor: "pointer",
  transition: "opacity 0.3s",
};

const TextStyles = {
  fontFamily: "Raleway, sans-serif", // Include Raleway font
  paddingLeft: "90px",
  paddingTop: "150px",
};

const bgStyle = {
  backgroundImage: `url(${bg})`,
  backgroundSize: "cover",
  opacity: 0.3,
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  zIndex: 0,
  pointerEvents: "none", // Keep pointerEvents as "none" for the background
};

export default function ImHome() {
  return (
    <div>
      <div style={bgStyle}></div>
      <div
        style={{
          height: "57vw",
          overflow: "hidden",
          position: "relative",
          fontFamily: "Raleway, sans-serif",
        }}
      >
        <h1 style={TextStyles}>CORPBRAND SOLUTIONS LLP</h1>
        <TypingText>
          Cultivating Success with Strategic Digital Solutions
        </TypingText>
        <div
          style={{
            display: "flex",
            paddingTop: "10rem",
            paddingLeft: "4rem",
          }}
        >
          <div
            style={{
              width: "10rem",
              height: "10rem",
              backgroundColor: "rgba(204, 204, 255, 0.5)",
              borderRadius: "20px",
              marginRight: "40px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              transition: "opacity 0.3s",
              zIndex: "100",
            }}
          >
            <a
              href="https://www.facebook.com/corpbrandsolutions/?show_switched_toast=0&show_invite_to_follow=0&show_switched_tooltip=0&show_podcast_settings=0&show_community_review_changes=0&show_community_rollback=0&show_follower_visibility_disclosure=0"
              style={{
                ...iconStyle,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <FaFacebook />
            </a>
          </div>
          <div
            style={{
              width: "10rem",
              height: "10rem",
              backgroundColor: "rgba(204, 204, 255, 0.5)",
              borderRadius: "20px",
              marginRight: "40px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              transition: "opacity 0.3s",
              zIndex: "100",
            }}
          >
            <a
              href="https://twitter.com/corpbrandsol/status/1686817696971993088?s=20"
              style={{
                ...iconStyle,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <FaTwitter />
            </a>
          </div>
          <div
            style={{
              width: "10rem",
              height: "10rem",
              backgroundColor: "rgba(204, 204, 255, 0.5)",
              borderRadius: "20px",
              marginRight: "40px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              transition: "opacity 0.3s",
              zIndex: "100",
            }}
          >
            <a
              href="https://www.instagram.com/corpbrandsolutions/"
              style={{
                ...iconStyle,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <FaInstagram />
            </a>
          </div>
        </div>
        <img
          src={img1}
          alt="bg_image"
          style={{
            width: "98vw",
            height: "57vw",
            position: "absolute",
            paddingLeft: "50%",
            top: 0,
            left: 0,
            zIndex: 10,
            pointerEvents: "none",
          }}
        ></img>
        <img
          src={img2}
          alt="workers"
          style={{
            width: "40vw",
            height: "30vw",
            position: "absolute",
            paddingLeft: "60%",
            top: "45%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        />
      </div>
      <div style={{ height: "20vw" }}>
        <div
          style={{
            display: "flex",
            paddingTop: "3.5rem",
            paddingLeft: "7rem",
            paddingRight: "7rem",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <img
            src={img7}
            alt="dm"
            style={{
              width: "12rem",
              height: "12rem",
              marginRight: "40px",
            }}
          ></img>
          <img
            src={img8}
            alt="cm"
            style={{
              width: "12rem",
              height: "12rem",
              marginRight: "40px",
            }}
          ></img>
          <img
            src={img9}
            alt="bl"
            style={{
              width: "12rem",
              height: "12rem",
              marginRight: "40px",
              borderRadius: "50%",
            }}
          ></img>
        </div>
      </div>
    </div>
  );
}
