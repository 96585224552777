import React from "react";
import { FaFacebook, FaTwitter, FaInstagram } from "react-icons/fa";
import TypingText from "../../components/TypingText/TypingText";
import tailoredMarketing from "/Users/ayushrawat/Desktop/landing_page/corpsol/src/pages/Home/images/tailored_marketing.png";
import img5 from "/Users/ayushrawat/Desktop/landing_page/corpsol/src/pages/Home/images/inside_bottom_home.png";
import curve from "/Users/ayushrawat/Desktop/landing_page/corpsol/src/pages/Home/images/curve.png";
import empowerBrand from "/Users/ayushrawat/Desktop/landing_page/corpsol/src/pages/Home/images/empower_brand.png";
import imgBg from "/Users/ayushrawat/Desktop/landing_page/corpsol/src/pages/Contact/contact_bg.avif";

const containerStyle = {
  backgroundImage: `linear-gradient(rgba(255, 255, 255,0.6), rgba(255, 255, 255, 0.6)), url(${imgBg})`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundAttachment: "fixed",
  height: "240vh",
};

const sectionStyle = {
  display: "flex",
  flexDirection: "column",
};

const paragraphStyle = {
  gap: "24px",
  fontFamily: "Raleway, sans-serif", // Convert to Raleway font
  width: "35rem",
  fontSize: "20px",
};

export default function About() {
  return (
    <div style={containerStyle}>
      <div style={sectionStyle}>
        {/* First Section */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            paddingTop: "3rem",
          }}
        >
          <img
            src={img5}
            alt="bottom_circle"
            style={{
              height: "20rem",
              marginTop: "5rem",
              marginLeft: "12rem",
              overflow: "hidden",
              opacity: "0.8",
            }}
          />
          <div>
            <p
              style={{
                fontSize: "24px",
                fontWeight: "600",
                fontFamily: "Raleway, sans-serif", // Convert to Raleway font
                marginLeft: "10rem",
                marginTop: "8rem",
              }}
            >
              Our Passion for Business Growth
            </p>
            <p
              style={{
                ...paragraphStyle,
                paddingLeft: "10rem",
                paddingTop: "1rem",
              }}
            >
              At CORPBRAND SOLUTIONS, we are passionate about helping businesses
              level-up their marketing strategies and unlock their true
              potential. With a team of dedicated marketing experts and creative
              minds, we are committed to elevating your brand presence and
              engaging potential customers like never before.
            </p>
          </div>
        </div>

        {/* Second Section */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            paddingTop: "3rem",
          }}
        >
          <div>
            <p
              style={{
                fontSize: "24px",
                fontWeight: "600",
                fontFamily: "Raleway, sans-serif", // Convert to Raleway font
                marginLeft: "10rem",
                marginTop: "8rem",
              }}
            >
              Tailored Marketing Strategies
            </p>
            <p
              style={{
                ...paragraphStyle,
                paddingLeft: "10rem",
                paddingTop: "1rem",
              }}
            >
              We understand that each business is unique, and there's no
              one-size-fits-all marketing solution. Our team takes a tailored
              approach, carefully crafting marketing strategies that align with
              your brand's goals and values. Our primary focus is to deliver
              tangible results that drive growth and success for your business.
              Whether it's increasing brand awareness, driving website traffic,
              or boosting conversions, we've got you covered.
            </p>
          </div>
          <img
            src={tailoredMarketing}
            alt="bottom_circle"
            style={{
              height: "20rem",
              marginTop: "5rem",
              marginLeft: "5rem",
              overflow: "hidden",
              opacity: "0.8",
            }}
          />
        </div>

        {/* Third Section */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            paddingTop: "3rem",
          }}
        >
          <img
            src={curve}
            alt="bottom_circle"
            style={{
              height: "20rem",
              marginTop: "5rem",
              marginLeft: "5rem",
              overflow: "hidden",
              opacity: "0.8",
            }}
          />
          <div>
            <p
              style={{
                fontSize: "24px",
                fontWeight: "600",
                fontFamily: "Raleway, sans-serif", // Convert to Raleway font
                marginLeft: "5rem",
                marginTop: "8rem",
              }}
            >
              Staying Ahead of the Curve
            </p>
            <p
              style={{
                ...paragraphStyle,
                paddingLeft: "5rem",
                paddingTop: "1rem",
              }}
            >
              We are constantly exploring new marketing trends and staying ahead
              of the curve. Our innovative ideas ensure that your brand stands
              out in the ever-evolving digital landscape. We believe in building
              strong relationships with our clients. When you choose CORPBRAND
              SOLUTIONS, you'll experience a collaborative partnership where
              your ideas and input are valued throughout the process.
            </p>
          </div>
        </div>

        {/* Fourth Section */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            paddingTop: "3rem",
          }}
        >
          <div>
            <p
              style={{
                fontSize: "24px",
                fontWeight: "600",
                fontFamily: "Raleway, sans-serif", // Convert to Raleway font
                marginLeft: "10rem",
                marginTop: "8rem",
              }}
            >
              Empowering Your Brand
            </p>
            <p
              style={{
                ...paragraphStyle,
                paddingLeft: "10rem",
                paddingTop: "1rem",
              }}
            >
              The digital landscape is always evolving, and so are we. We
              continuously analyze, refine, and optimize our strategies to
              ensure your brand remains at the forefront of the market. Our
              mission is to empower your brand with the tools, strategies, and
              confidence needed to conquer the market and outshine your
              competitors.
            </p>
          </div>
          <img
            src={empowerBrand}
            alt="bottom_circle"
            style={{
              height: "20rem",
              marginTop: "5rem",
              marginLeft: "5rem",
              overflow: "hidden",
              opacity: "0.8",
            }}
          />
        </div>
      </div>
    </div>
  );
}
