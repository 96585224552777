import React, { useState, useEffect } from "react";
import clients from "/Users/ayushrawat/Desktop/landing_page/corpsol/src/pages/Testimonials/clients-real.jpg";
import bg from "/Users/ayushrawat/Desktop/landing_page/corpsol/src/pages/Contact/contact_bg.avif";

const Testimonial = ({ review, name }) => {
  const containerStyle = {
    fontFamily: "Raleway, sans-serif", // Set font-family to Raleway
    width: "40rem",
    height: "10rem",
    textAlign: "center",
    padding: "20px",
    fontSize: "24px",
    fontWeight: "bold",
    border: "2px solid #e6e6fa",
    borderRadius: "10px",
    position: "relative",
    marginBottom: "200px",
    marginTop: "100px",
    backgroundColor: "#f5f5ff",
    boxShadow: "5px 5px 5px #e6e6fa",
  };

  const textInsideBoxStyle = {
    fontSize: "20px",
    padding: "0 10px",
  };

  return (
    <div style={containerStyle}>
      <div style={textInsideBoxStyle}>
        <p>{review}</p>
        <p>- {name}</p>
      </div>
    </div>
  );
};

const TextSlider = () => {
  const texts = [
    {
      review:
        "Our online reputation was transformed after partnering with this digital marketing company. Their ORM strategies were effective, helping us regain trust and credibility in the market. Highly recommended!",
      name: "Anuj Kaushal, WWICS",
    },
    {
      review:
        "With their expert SEO techniques, our website traffic increased significantly, leading to higher conversions and improved visibility in search engines. They truly understand how to make your brand stand out.",
      name: "Priyanka Sharma, Finndit",
    },
    {
      review:
        "Their digital marketing strategies played a pivotal role in our successful product launch. The team's innovative approach and attention to detail ensured that our brand received the right exposure and engagement from the target audience.",
      name: "Renu Tyagi, Destination",
    },
    {
      review:
        "We entrusted them with our campaign management, and they exceeded our expectations. Their comprehensive understanding of consumer behavior and market trends helped us achieve remarkable results, boosting our sales and brand recognition.",
      name: "   Gurtej Singh",
    },
    {
      review:
        "The digital marketing company's dedication to our brand's success was evident throughout our collaboration. Their tailored campaigns and insightful analytics not only increased our online presence but also fostered a stronger connection with our customers.",
      name: "Atharva",
    },
  ];

  const [index, setIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((index) => (index + 1) % texts.length);
    }, 5000);
    return () => clearInterval(interval);
  }, [texts.length]);

  const bgStyle = {
    backgroundImage: `url(${bg})`,
    opacity: "0.2",
    backgroundSize: "cover",
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: -1,
    transform: "rotate(180deg)",
  };

  const clientsImageStyle = {
    width: "90%",
    //marginTop: "10px",
    marginBottom: "150px",
  };

  const columnStyle = {
    fontFamily: "Raleway, sans-serif", // Set font-family to Raleway
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100vh",
    //marginTop: "10px",
  };

  return (
    <div>
      <div style={columnStyle}>
        <div style={bgStyle}></div>
        <h1 style={{ top: "-50px" }}>Our Clients</h1>
        <img src={clients} alt="clients" style={clientsImageStyle} />
        <h1>Client's Rave Reviews</h1>
        <Testimonial review={texts[index].review} name={texts[index].name} />
      </div>
    </div>
  );
};

export default TextSlider;
