import React from "react";
import { FaWhatsapp, FaEnvelope, FaDirections } from "react-icons/fa";
import Map from "../../components/Map/Map";
import img_bg from "./contact_bg.avif";

const ImContact = () => {
  const chatStyle = {
    fontFamily: "Raleway, sans-serif", // Set font-family to Raleway
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "15rem",
    height: "2rem",
    backgroundColor: "rgba(255,255,255)",
    border: "2px solid black",
    color: "black",
    textDecoration: "none",
    borderRadius: "10px",
    padding: "0.5rem",
    fontWeight: "lighter",
  };

  const emailStyle = {
    fontFamily: "Raleway, sans-serif", // Set font-family to Raleway
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "16rem",
    height: "2.5rem",
    backgroundColor: "rgba(255,255,255)",
    fontWeight: "lighter",
    border: "2px solid black",
    color: "black",
    textDecoration: "none",
    borderRadius: "10px",
    marginTop: "1.5rem",
  };

  const buttonStyle = {
    fontFamily: "Raleway, sans-serif", // Set font-family to Raleway
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "16rem",
    height: "2.5rem",
    backgroundColor: "rgba(255,255,255)",
    border: "2px solid black",
    color: "black",
    textDecoration: "none",
    marginTop: "1.5rem",
    borderRadius: "10px",
    fontWeight: "lighter",
  };

  const mapUrl =
    "https://www.google.com/maps/dir//Gaur+City+Mall,+Greater+Noida+W+Rd,+Gaur+City+1,+Sector+4,+Greater+Noida,+Ghaziabad,+Uttar+Pradesh+201009/@28.6060285,77.4296325,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x390cef6165728471:0xf027989e066991a4!2m2!1d77.4296325!2d28.6060285?entry=ttu";

  const chatUrl = `https://api.whatsapp.com/send?phone=9560182277`;

  const defaultEmail = "sunil@corpbrandsol.com";
  const emailUrl = `mailto:${defaultEmail}`;

  const redirectToChat = () => {
    window.location.href = chatUrl;
  };

  return (
    <div style={{ position: "relative", marginBottom: "3rem" }}>
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "50vw",
          background: `url(${img_bg}) no-repeat center center`,
          backgroundSize: "cover",
          opacity: 0.5,
          zIndex: -1,
        }}
      />
      <div style={{ marginLeft: "2rem", marginRight: "2rem" }}>
        <Map />
      </div>
      <div
        className="contact-email"
        style={{
          fontFamily: "Raleway, sans-serif", // Set font-family to Raleway
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div
          className="contact"
          style={{
            fontFamily: "Raleway, sans-serif", // Set font-family to Raleway
            display: "flex",
            flexDirection: "column",
            width: "20rem",
            paddingLeft: "20rem",
            paddingTop: "2rem",
          }}
        >
          <h2 style={{ fontFamily: "Raleway, sans-serif" }}>Contact Info</h2>
          <p style={{ fontFamily: "Raleway, sans-serif", fontWeight: "bold" }}>
            Address:{" "}
            <span
              style={{
                fontFamily: "Raleway, sans-serif",
                fontWeight: "lighter",
              }}
            >
              972, 9th floor, Gaur City mall, Greater Noida, Uttar Pradesh,
              India
            </span>
          </p>
          <p style={{ fontFamily: "Raleway, sans-serif", fontWeight: "bold" }}>
            Phone:{" "}
            <span
              style={{
                fontFamily: "Raleway, sans-serif",
                fontWeight: "lighter",
              }}
            >
              9560182277
            </span>
          </p>
          <p style={{ fontFamily: "Raleway, sans-serif", fontWeight: "bold" }}>
            Email:{" "}
            <span
              style={{
                fontFamily: "Raleway, sans-serif",
                color: "blue",
                fontWeight: "lighter",
              }}
            >
              sunil@corpbrandsol.com
            </span>
          </p>
        </div>
        <div
          className="email"
          style={{
            fontFamily: "Raleway, sans-serif", // Set font-family to Raleway
            display: "flex",
            flexDirection: "column",
            width: "20rem",
            paddingLeft: "10rem",
            paddingTop: "2rem",
          }}
        >
          <h2 style={{ fontFamily: "Raleway, sans-serif" }}>
            Get in Touch with Us
          </h2>
          <div className="whatsapp">
            <a href={chatUrl} onClick={redirectToChat} style={chatStyle}>
              <FaWhatsapp
                style={{ marginRight: "0.5rem", fontWeight: "lighter" }}
              />
              Chat With Us On Whatsapp
            </a>
          </div>
          <div>
            <a href={emailUrl} style={emailStyle}>
              <FaEnvelope style={{ marginRight: "0.5rem" }} />
              Email us
            </a>
          </div>
          <div>
            <a href={mapUrl} target="_blank" style={buttonStyle}>
              <FaDirections style={{ marginRight: "0.5rem" }} />
              Get Directions
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImContact;
