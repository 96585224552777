import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import services_1 from "/Users/ayushrawat/Desktop/landing_page/corpsol/src/pages/Services/images/serrvices_bg.gif";
import "/Users/ayushrawat/Desktop/landing_page/corpsol/src/pages/Services/styles.css";
import { Link } from "react-router-dom";

const Page = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "200vh",
    overflow: "hidden",
    position: "relative",
    fontFamily: "Raleway, sans-serif", // Apply Raleway font
  };

  const slantedBackgroundStyle = {
    position: "relative",
    bottom: 0,
    left: 0,
    width: "100%",
    height: "250vh",

    background: "linear-gradient(to bottom, #e6e6fa 60%, #d8b6ff)",
    display: "flex",
    flexDirection: "column",
    zIndex: 2,
  };

  const imgStyle = {
    width: "30rem",
    height: "20rem",
    position: "absolute",
    bottom: "20%",
    right: "3rem",
  };

  const greyDivStyle = {
    backgroundColor: "white", // Set your desired background color here
    height: "220vh",
    position: "relative",
    width: "100%",
    zIndex: 1,
  };

  const cardContainer = {
    marginLeft: "23rem",
    marginTop: "10rem",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
    width: "50%",
    alignItems: "center",
  };

  return (
    <div style={containerStyle}>
      <div style={slantedBackgroundStyle}>
        <p
          style={{
            color: "black",
            paddingLeft: "5rem",
            paddingTop: "5rem",
            fontSize: "50px",
          }}
        >
          Our Services
        </p>
        <p style={{ paddingLeft: "5rem", fontSize: "30px", color: "black" }}>
          Amplify Your Brand, Ignite Your Reach: Unleashing Digital Success
          Together
        </p>
        <img src={services_1} alt="img" style={imgStyle} />
      </div>
      <div style={greyDivStyle}>
        <div style={cardContainer}>
          <Link to="/orm" style={{ textDecoration: "none" }}>
            <div
              className="card"
              data-aos="fade-down-right"
              data-aos-duration="1500"
            >
              <h3>
                <div>{"< >"}</div>Online Reputation Management
              </h3>
            </div>
          </Link>
          <Link to="/digital-marketing" style={{ textDecoration: "none" }}>
            <div
              className="card"
              data-aos="fade-down-left"
              data-aos-duration="1500"
            >
              <h3>
                <div>{"< >"}</div>Digital Marketing
              </h3>
            </div>
          </Link>
          <Link to="/campaign-management" style={{ textDecoration: "none" }}>
            <div
              className="card"
              data-aos="fade-up-right"
              data-aos-duration="1500"
            >
              <h3>
                <div>{"< >"}</div>Campaign Management
              </h3>
            </div>
          </Link>
          <Link to="/brand-launch" style={{ textDecoration: "none" }}>
            <div
              className="card"
              data-aos="fade-up-left"
              data-aos-duration="1500"
            >
              <h3>
                <div>{"< >"}</div>Brand Launch
              </h3>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Page;
