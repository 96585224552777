import React from "react";

const Map = () => {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <iframe
        title="map"
        width="100%"
        height="350"
        style={{ border: 0 }}
        loading="lazy"
        allowFullScreen
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14018.115796145947!2d77.4270576!3d28.6060332!3m2!1i1024!2i768!4f13.1!4m3!3e2!4m0!4m0!5e0!3m2!1sen!2s!4v1630187816959!5m2!1sen!2s"
      ></iframe>
    </div>
  );
};

export default Map;
