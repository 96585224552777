import React, { useState, useEffect } from "react";

const TypingText = () => {
  const [text, setText] = useState("");
  const [isFinished, setIsFinished] = useState(false);
  const fullText = "Cultivating Success with Strategic Digital Solutions";

  useEffect(() => {
    let index = 0;
    let isDeleting = false;
    let count = 0;

    const typingEffect = () => {
      const currentText = fullText.substring(0, index);

      if (isDeleting) {
        setText(currentText);
        index--;

        if (index < 0) {
          isDeleting = false;
        }
      } else {
        setText(currentText);
        index++;

        if (index === fullText.length + 1) {
          isDeleting = true;
          index = fullText.length;
          count++;
        }
      }

      if (count === 2) {
        setText(fullText);
        setIsFinished(true);
        clearInterval(intervalId);
      }
    };

    let intervalId;
    if (!isFinished) {
      intervalId = setInterval(typingEffect, 100);
    }

    return () => clearInterval(intervalId);
  }, [fullText, isFinished]);

  return <div style={{ fontSize: "24px", paddingLeft: "90px" }}>{text}</div>;
};

export default TypingText;
