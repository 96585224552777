import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar/ImNavbar";
import ImHome from "./pages/Home/ImHome";
import AboutUs from "./pages/About/ImAbout";
import Services from "./pages/Services/ImServices";
import Testimonials from "./pages/Testimonials/ImTestimonials";
import Contact from "./pages/Contact/ImContact";
import ImORM from "./pages/Services/ImORM";
import ImDigitalMarketing from "./pages/Services/ImDigitalMarketing";
import ImCampaignManagement from "./pages/Services/ImCampaignManagement";
import ImBrandLaunch from "./pages/Services/ImBrandLaunch";
import ImFooter from "./components/Footer/ImFooter";

function App() {
  return (
    <Router>
      <div>
        <Navbar />
        <Routes>
          <Route path="" element={<ImHome />} />
          <Route path="/home" element={<ImHome />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/services" element={<Services />} />
          <Route path="/orm" element={<ImORM />} />
          <Route path="/digital-marketing" element={<ImDigitalMarketing />} />
          <Route
            path="/campaign-management"
            element={<ImCampaignManagement />}
          />
          <Route path="/brand-launch" element={<ImBrandLaunch />} />
          <Route path="/testimonials" element={<Testimonials />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
        <div>
          <ImFooter />
        </div>
      </div>
    </Router>
  );
}

export default App;
